<template>
  <div class="success-modal" ref="successModalRef">
    <div class="content">
      <span>
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-duigou-jiacu"></use>
        </svg>
      </span>
      <p>We've sent a verification email to {{ email }}.<br/>Please click the link in the email to complete the registration.</p>
      <p>Didn’t receive the email? You may need to check your spam folder.</p>
      <div class="btn">
        <artmisads-button @click="resendEmail">Resend Email</artmisads-button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';

  const props = defineProps(['showSuccessModal', 'email', 'setShowSuccessModal', 'resendEmail']);

  const successModalRef = ref(null);

</script>
<style lang="less">
  .success-modal {
    position: absolute;
    background: var(--color-white);
    left: -56px;
    right: -56px;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: var(--section-box-shadow-2);
    border-radius: 8px;
    .content {
      display: flex;
      flex-direction: column;
      > span {
        width: 50px;
        height: 50px;
        background-color: var(--green-5);
        box-shadow: 0 0 0 7px var(--green-6);
        border-radius: 100%;
        align-self: center;
        display: flex;
        justify-content: center;
        margin-bottom: 22px;
        > svg {
          width: 32px;
          height: 32px;
          align-self: center;
          fill: rgba(255,255,255,0.85);
        }
      }
      
      > p {
        color: var(--dark-2);
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        margin-top: 12px;
        &:first-of-type {
          padding: 0 20px;
          font-size: 16px;
          font-weight: 600;
          color: var(--dark-1);
        }
      }
      .btn {
        margin-top: 24px;
        display: flex;
        justify-content: center;
      }
    }
  }
</style>